import React from "react"

function Logo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 163.5 58.3">
      <path
        fill="#3c3c3b"
        d="M20.1 0v23.9h-4.5V13H5.3v10.9H.8V0h4.5v9h10.3V0h4.5zM23.1 12c.1-.8.1-1.6.7-2.5 1.6-2.9 5.7-2.9 6.7-2.9 1.6 0 3.6.2 5.1 1.1 2 1.2 2 2.8 2 4.6v9c0 1.3 0 1.7.7 2.7h-4.8c0-.4-.1-1.1-.1-1.3-.9.7-2.7 1.7-5 1.7-3.8 0-6.1-2.4-6.1-5.3 0-.8.2-1.6.5-2.3 1.2-2.4 3.8-3 5.5-3.3 3.5-.7 3.8-.8 5.3-1.3 0-.8-.1-2.6-2.9-2.6-1.7 0-2.6.5-3 1.1-.4.5-.4 1-.4 1.5l-4.2-.2zm10.5 3.5c-.8.4-1.7.7-3.6 1-1.5.2-3.4.5-3.4 2.3 0 1.3 1.1 2 2.7 2 2.1 0 3.5-.9 4-2 .2-.5.2-1 .2-1.5v-1.8zM59.2 7h4.2l-5.1 16.9h-4l-3-10.8L48 23.9h-4L39.1 7h4.2l2.9 11.6L49.4 7H53l3.1 11.6L59.2 7zM71.3 9.9v7.7c0 2.1 0 3 1.9 3 .6 0 1.1 0 1.7-.2v3.4c-1.2.2-2 .3-2.9.3-.8 0-3.7 0-4.5-2.1-.3-.7-.3-1.8-.3-3.5V9.9h-2.5V7h2.5V1h4.1v6h3.6v2.9h-3.6zM80.8 0v8.7c1.3-1.3 3-2.2 5.4-2.2 2.1 0 3.7.8 4.6 1.8 1.4 1.5 1.4 3.2 1.4 5.2V24h-4v-9.3c-.1-1.5-.1-1.9-.3-2.4-.1-.3-.7-1.9-2.9-1.9-2.6 0-3.8 2.1-4.1 3.1-.1.5-.1.8-.1 2.7V24h-4.1V0h4.1zM103.4 24.4c-5.3 0-8.8-3.9-8.8-9 0-5.2 3.6-8.9 8.6-8.9 4.7 0 8.7 3.3 8.8 8.9 0 5.3-3.5 9-8.6 9zm-.2-14.3c-2.5 0-4.4 2-4.4 5.2 0 3.5 2.1 5.4 4.6 5.4 2.9 0 4.4-2.6 4.3-5.4-.1-2.8-1.7-5.2-4.5-5.2zM118.3 7v2.4c.5-.9 1.6-2.9 5.4-2.8v4.2c-.9 0-2.4.1-3.7 1-1.7 1.3-1.8 3-1.8 5.9V24h-3.9V7h4zM125.3 7h4.1v2c.2-.3.6-.8 1.3-1.3 1-.7 2.6-1.2 3.9-1.2 1.9 0 3.6.9 4.4 2 1.1 1.4 1.1 3.3 1.1 5.5v10h-4v-8.6c0-1.3 0-2.1-.1-2.6-.4-1.5-1.5-2.3-2.8-2.3-1.3 0-2.5.8-3.1 1.9-.6 1.3-.6 3-.6 4.2V24h-4.1V7zM159.4 20.1c-2.1 2.8-4.8 4.3-8.3 4.3-4.4 0-8.7-2.8-8.7-9 0-5.7 3.7-8.9 8.4-8.9 4.5 0 6.3 2.7 6.7 3.2 1.4 2 1.7 4.9 1.7 6.6h-12.5c.5 2.7 2.1 4.2 4.8 4.2 2.8 0 3.9-1.6 4.4-2.3l3.5 1.9zm-4.6-6.8c-.4-2.6-2-3.6-3.8-3.6-2.2 0-3.7 1.3-4.1 3.6h7.9z"
      />
      <g>
        <path
          fill="#b2b2b2"
          d="M21.5 45.9c-.3.8-.9 2.2-2.1 3.6-1.7 2-4.4 3.7-8.3 3.7C4.8 53.2 0 48.6 0 40.6c0-8.1 5.3-12.5 11.4-12.5 7.5 0 9.2 5.7 9.8 7.4l-4.5 1.2c-.1-.4-.3-1.5-.9-2.4-.5-.7-1.8-2.1-4.3-2.1-3.9 0-6.6 3.2-6.6 8.3 0 5.5 2.9 8.5 6.6 8.5 2.2 0 3.6-1.1 4.6-2.3.8-1.1 1.1-1.9 1.3-2.5l4.1 1.7zM32.2 53.1c-5.3 0-8.8-3.9-8.8-9s3.6-8.9 8.6-8.9c4.7 0 8.7 3.3 8.8 8.9 0 5.2-3.5 9-8.6 9zM32 38.8c-2.5 0-4.4 2-4.4 5.2 0 3.5 2.1 5.4 4.6 5.4 2.9 0 4.4-2.6 4.3-5.4 0-2.8-1.7-5.2-4.5-5.2zM43 35.7h4.1v2c.2-.3.6-.8 1.3-1.3 1-.7 2.6-1.2 3.9-1.2 1.9 0 3.6.9 4.4 2 1.1 1.4 1.1 3.3 1.1 5.5v10h-4V44c0-1.3 0-2.1-.1-2.6-.4-1.5-1.5-2.3-2.8-2.3-1.3 0-2.5.8-3.1 1.9-.6 1.3-.6 3-.6 4.2v7.4H43V35.7zM62.6 47.6c1 1.3 2.5 2.2 4.9 2.2 2.3 0 3.4-1.2 3.4-2 0-1.1-1.2-1.4-1.8-1.5-.6-.1-3.4-.6-4-.8-4-.9-4.8-3.3-4.8-4.8 0-2.9 2.6-5.5 7.3-5.5 2.6 0 4.4.8 5.6 1.6.8.5 1.4 1.1 1.7 1.5L72 41c-.5-.9-1.6-2.6-4.6-2.6-1.9 0-2.8.8-2.8 1.7 0 1.2 1.3 1.6 3.4 1.9 3.5.6 4.5.8 5.6 1.7 1.1.8 1.7 2.2 1.7 3.6 0 2.6-2.4 5.8-7.6 5.8-3 0-6-.9-8.1-3l3-2.5zM93.1 35.7v16.9H89v-1.7c-.7.7-2.4 2.1-5.5 2.1-2.8 0-4.1-1.2-4.7-2-1.2-1.5-1.2-3-1.2-5.6v-9.8h4.1v9.7c0 .7 0 1.3.1 1.7.4 1.5 1.7 2.1 3.2 2.1 1.7 0 2.7-.9 3.3-1.8.6-1.1.6-2.2.7-4.6v-7.2h4.1zM99.9 28.7v23.9h-4.1V28.7h4.1zM108.6 38.6v7.7c0 2.1 0 3 1.9 3 .6 0 1.1 0 1.7-.2v3.4c-1.2.2-2 .3-2.9.3-.8 0-3.7 0-4.5-2.1-.3-.7-.3-1.8-.3-3.5v-8.6H102v-2.9h2.5v-6h4.1v6h3.6v2.9h-3.6zM118.4 28.9V33h-4v-4.1h4zm0 6.8v16.9h-4V35.7h4zM121 35.7h4.1v2c.2-.3.6-.8 1.3-1.3 1-.7 2.6-1.2 3.9-1.2 1.9 0 3.6.9 4.4 2 1 1.4 1 3.3 1 5.5v10h-4V44c0-1.3 0-2.1-.1-2.6-.4-1.5-1.5-2.3-2.8-2.3-1.3 0-2.5.8-3.1 1.9-.6 1.3-.6 3-.6 4.2v7.4H121V35.7zM154.6 35.7v13.9c0 3.4 0 4.7-1.1 6.1-.9 1.3-3 2.6-7 2.6-6 0-7.2-2.6-7.8-5.3h4.5c.3 1 1 2.1 3.5 2.1 2.1 0 3-.8 3.4-1.4.6-.8.6-1.8.6-3.9-.9.8-2.6 1.8-5.1 1.8-4 0-7.4-2.7-7.4-8.1 0-6.3 4.5-8.4 7.6-8.4 3.1 0 4.3 1.7 4.8 2.3v-1.8h4zm-8.1 3.1c-2.1 0-4.2 1.4-4.2 4.6 0 2.9 1.8 4.5 4.2 4.5 2.2 0 4.1-1.2 4.2-4.3 0-2.8-1.5-4.8-4.2-4.8z"
        />
      </g>
      <g>
        <path
          className="st1"
          d="M155.8 33.9h1.3v.3h-.4v1.4h-.4v-1.4h-.4v-.3zM159.4 33.9v1.7h-.4v-1.4V34.5l-.3 1.2h-.4l-.3-1.1-.1-.3V35.7h-.4V34h.6l.3 1 .1.3v-.2l.3-1h.6z"
        />
      </g>
      <path fill="#999" d="M163.5 7.6L156 0h7.5z" />
    </svg>
  )
}

export default Logo
