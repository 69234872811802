import React from "react"

const SVG = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-41 0 511 512"
    className={`${className || ""}`}
  >
    <path
      className="fill-current"
      d="M290.941 127.27c-4.664-4.66-10.863-7.23-17.457-7.23s-12.793 2.57-17.453 7.23c-4.664 4.664-7.234 10.863-7.234 17.457s2.57 12.793 7.23 17.453l42.055 42.054c4.66 4.66 10.863 7.23 17.457 7.23s12.79-2.57 17.453-7.23l89.852-89.851c4.664-4.664 7.23-10.863 7.23-17.453 0-6.594-2.566-12.793-7.23-17.457-4.66-4.664-10.864-7.23-17.457-7.23s-12.793 2.566-17.453 7.23l-9.907 9.906a95.422 95.422 0 00-23.93-13.344v-11.23c0-13.614-11.074-24.688-24.683-24.688h-77.406v-8.57C252.008 14.152 237.855 0 220.46 0h-86.324c-17.395 0-31.547 14.152-31.547 31.547V67.19c0 9.664 7.863 17.528 17.527 17.528H234.48c9.665 0 17.528-7.864 17.528-17.528v-12.05h77.402c5.328 0 9.664 4.336 9.664 9.664v6.918a95.417 95.417 0 00-18.914-1.914c-52.613 0-95.418 42.804-95.418 95.418 0 52.613 42.805 95.418 95.418 95.418 6.418 0 12.746-.645 18.914-1.883v228.554c0 5.329-4.336 9.66-9.664 9.66H25.184c-5.329 0-9.66-4.331-9.66-9.66V64.805c0-5.328 4.331-9.664 9.66-9.664h49.933a7.512 7.512 0 000-15.024H25.184C11.574 40.117.5 51.191.5 64.805v422.511C.5 500.926 11.574 512 25.184 512h304.23c13.61 0 24.684-11.074 24.684-24.684V254.418a95.25 95.25 0 0032.43-20.633c17.843-17.277 28.144-40.441 28.995-65.226a7.515 7.515 0 00-7.25-7.766c-4.117-.137-7.62 3.102-7.765 7.25-1.2 34.887-24.14 63.691-55.645 73.742a7.136 7.136 0 00-1.254.41c-7.414 2.23-15.285 3.426-23.449 3.426-44.328 0-80.394-36.066-80.394-80.394 0-44.329 36.066-80.395 80.394-80.395a80.254 80.254 0 0147.137 15.277l-51.758 51.762zM236.984 67.19a2.504 2.504 0 01-2.504 2.504H120.117a2.504 2.504 0 01-2.504-2.504V31.547c0-9.113 7.41-16.524 16.524-16.524h86.324c9.11 0 16.523 7.41 16.523 16.524zM398.56 90.098a9.582 9.582 0 016.828-2.832 9.616 9.616 0 016.836 2.832c3.765 3.765 3.765 9.898 0 13.664l-89.856 89.851a9.6 9.6 0 01-6.832 2.832 9.6 9.6 0 01-6.832-2.832l-42.055-42.054a9.597 9.597 0 01-2.828-6.832 9.584 9.584 0 012.832-6.832 9.6 9.6 0 016.832-2.833 9.607 9.607 0 016.832 2.833l29.91 29.91a7.51 7.51 0 0010.622 0zm0 0"
    />
    <path
      className="fill-current"
      d="M204.938 188.547H113.41a7.509 7.509 0 00-7.508 7.512 7.508 7.508 0 007.508 7.507h91.528a7.511 7.511 0 007.511-7.507 7.512 7.512 0 00-7.512-7.512zm0 0M99.074 111.012H48.531a7.512 7.512 0 000 15.023h50.543a7.512 7.512 0 000-15.023zm0 0M113.41 238.414h91.528a7.512 7.512 0 007.511-7.512 7.511 7.511 0 00-7.512-7.507H113.41a7.508 7.508 0 00-7.508 7.507 7.509 7.509 0 007.508 7.512zm0 0M236.555 279.648H113.41a7.509 7.509 0 00-7.508 7.512 7.509 7.509 0 007.508 7.512h123.145a7.512 7.512 0 000-15.024zm0 0M113.41 329.52h61.574a7.509 7.509 0 007.508-7.512 7.509 7.509 0 00-7.508-7.512H113.41a7.509 7.509 0 00-7.508 7.512 7.509 7.509 0 007.508 7.512zm0 0M113.41 385.871h91.528a7.512 7.512 0 000-15.023H113.41c-4.148 0-7.508 3.363-7.508 7.511s3.36 7.512 7.508 7.512zm0 0M113.41 420.715h123.145a7.512 7.512 0 007.511-7.512 7.511 7.511 0 00-7.511-7.508H113.41a7.508 7.508 0 00-7.508 7.508 7.509 7.509 0 007.508 7.512zm0 0M64.473 182.035c-16.73 0-30.34 13.61-30.34 30.34s13.61 30.34 30.34 30.34 30.34-13.61 30.34-30.34-13.61-30.34-30.34-30.34zm0 45.656c-8.446 0-15.317-6.87-15.317-15.316s6.871-15.316 15.317-15.316c8.445 0 15.316 6.87 15.316 15.316s-6.871 15.316-15.316 15.316zm0 0M64.473 274.242c-16.73 0-30.34 13.613-30.34 30.34 0 16.73 13.61 30.34 30.34 30.34s30.34-13.61 30.34-30.34c0-16.727-13.61-30.34-30.34-30.34zm0 45.66c-8.446 0-15.317-6.87-15.317-15.316 0-8.45 6.871-15.32 15.317-15.32 8.445 0 15.316 6.87 15.316 15.32 0 8.445-6.871 15.316-15.316 15.316zm0 0M64.473 365.441c-16.73 0-30.34 13.61-30.34 30.34s13.61 30.34 30.34 30.34 30.34-13.61 30.34-30.34-13.61-30.34-30.34-30.34zm0 45.657c-8.446 0-15.317-6.871-15.317-15.317s6.871-15.316 15.317-15.316c8.445 0 15.316 6.87 15.316 15.316s-6.871 15.317-15.316 15.317zm0 0"
    />
  </svg>
)

export default SVG
