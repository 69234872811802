import React from "react"
import { Link } from "gatsby"
import Icon from "./icon/index.js"
import jobAd from "../../static/QuantitySurveyorJob.pdf"

// import { LinkedinLogo } from "../icon/index.js"

function Footer() {
  return (
    <footer className="w-full bg-gray-50 md:fixed md:bottom-0 md:right-0 md:left-0 md:h-122 -z-10">
      <div className="container px-4 py-12 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <div>
          <h2 className="text-3xl font-extrabold leading-none text-gray-900 sm:text-4xl">
            Ready to start a project?
            <br />
            <span className="text-blue-200">Contact us now</span>
          </h2>
          <div className="inline-flex mt-10 rounded-md shadow lg:mt-16">
            <Link
              to="/contact"
              className="inline-flex items-center justify-center px-8 py-6 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-blue-200 rounded-md hover:text-blue-200 hover:bg-white focus:outline-none focus:shadow-outline"
            >
              Contact
            </Link>
          </div>
        </div>

        <div className="mt-10 lg:mt-0">
          <div>
            <h2 className="mb-12 text-lg font-extrabold leading-none text-gray-900 sm:text-xl">
              Follow us in Linkedin:
            </h2>

            <div className="flex-shrink-0 w-24 mb-8 -mt-4 xs:mb-0">
              <a href="https://uk.linkedin.com/company/hawthorne-consulting-ni-ltd">
                <Icon name="linkedin" />
              </a>
            </div>
          </div>
          <div>
            <h2 className="text-lg font-extrabold leading-none text-blue-200 sm:text-xl">
              Join the team!
            </h2>
            <div className="inline-flex items-center justify-center px-8 py-6 mt-4 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-blue-200 rounded-md hover:text-blue-200 hover:bg-white focus:outline-none focus:shadow-outline">
              <a
                href={jobAd}
                title="Hawthorne Consulting Quantity Surveyor"
                target="_new"
              >
                <span class="btnText">See Jobs</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-12 md:flex md:items-center md:justify-between">
        <p className="text-sm leading-6 text-gray-400">
          &copy; {new Date().getFullYear()} Hawthorne Consulting, Inc. All
          rights reserved.
        </p>
        {/* <p className="text-sm leading-6 text-gray-400 hover:text-blue-200">
          <a href="https://studiomindset.co.uk">
            Mindset. Branding and Web Design.
          </a>
        </p> */}
      </div>
    </footer>
  )
}

export default Footer
