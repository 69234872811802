import React from "react"

const SVG = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-39 0 486 486.2"
    className={`${className || ""}`}
  >
    <path
      className="fill-current"
      d="M57.7 372.8l14.402 21.598V480h16v-90.398L70.5 363.199zm0 0M176.102 208c13.597 0 24-10.398 24-24h-16c0 4.8-3.204 8-8 8h-16c-4.801 0-8-3.2-8-8h-16c0 13.602 10.398 24 24 24zm0 0"
    />
    <path
      className="fill-current"
      d="M252.898 272h-36.796v-28.8c22.398-8.802 37.597-29.598 40-53.598l8-2.403c9.597-3.199 16.796-12.8 16.796-22.398V144c0-13.602-10.398-24-24-24V72c0-40-32-72-72-72h-32c-40 0-72 32-72 72v48c-13.597 0-24 10.398-24 24v15.2c0 8 4 15.198 10.403 20l13.597 8.8c1.602 24.8 16.801 45.602 40 55.2V272H84.102c-46.403 0-84 37.602-84 83.2V480h16V355.2c0-36.802 30.398-67.2 67.199-67.2h40.8l24 29.602c-2.402 3.199-4 6.398-4 10.398v25.602l-15.203 96.796c-.796 5.602 1.602 12 6.403 15.204l23.199 17.597c5.602 4 13.602 4 19.2 0l23.198-17.597c4.801-3.204 7.204-9.602 6.403-15.204l-15.2-96.796V328c0-4-1.601-7.2-4-10.398l24-29.602h40.797c36.801 0 67.204 30.398 67.204 67.2V480h16V355.2c0-45.598-37.602-83.2-83.204-83.2zm-12.796-152H222.5c-4-12.8-17.602-19.2-30.398-14.398-7.204 2.398-12 8-14.403 14.398H158.5c-4-12.8-17.602-19.2-30.398-14.398-7.204 2.398-12 8-14.403 14.398H96.102V96.8h1.597c19.2 0 37.602-8.8 49.602-23.198C176.898 82.398 208.1 88 240.1 88zm-32 8c0 4.8-3.204 8-8 8-4.801 0-8-3.2-8-8s3.199-8 8-8c4.796 0 8 3.2 8 8zm-64 0c0 4.8-3.204 8-8 8-4.801 0-8-3.2-8-8s3.199-8 8-8c4.796 0 8 3.2 8 8zm8-112h32c31.199 0 56 24.8 56 56-32 0-63.204-5.602-92.801-15.2l-5.602-2.402-4 4.801c-9.597 13.602-24.8 21.602-41.597 21.602V72c0-31.2 24.796-56 56-56zm-56 168v-4L75.3 166.398c-2.403-1.597-3.2-4-3.2-6.398v-16c0-4.8 3.2-8 8-8H113.7c4 12.8 17.602 19.2 30.403 14.398 7.199-2.398 12-8 14.398-14.398h19.2c4 12.8 17.6 19.2 30.402 14.398 7.199-2.398 12-8 14.398-14.398h33.602c4.796 0 8 3.2 8 8v20.8c0 3.2-2.403 6.4-5.602 7.2l-18.398 6.398V184c0 26.398-21.602 48-48 48h-48c-26.403 0-48-21.602-48-48zm40 63.2c2.398 0 5.597.8 8 .8h48c2.398 0 5.597 0 8-.8V272h-64zm40 80.8v16h-16v-16zm-8 141.602L144.898 452l14.403-92h17.597l14.403 92.8zm4-157.602h-8l-19.204-24h46.403zm0 0"
    />
    <path
      className="fill-current"
      d="M248.102 389.602V480h16v-85.602l14.398-21.597-12.8-9.602zm0 0M384.102 32h-112v16h112c4.796 0 8 3.2 8 8v80c0 4.8-3.204 8-8 8H322.5l-24 72h-10.398v-16h-16v32h37.597l24-72h50.403c13.597 0 24-10.398 24-24V56c0-13.602-10.403-24-24-24zm0 0"
    />
    <path
      className="fill-current"
      d="M272.102 88h24v16h-24zm0 0M312.102 88h24v16h-24zm0 0M352.102 88h24v16h-24zm0 0"
    />
  </svg>
)

export default SVG
