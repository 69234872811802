import React from "react"

const SVG = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29.5 29.5"
    className="w-12 text-blue-200 "
  >
    <circle
      cx="14.7"
      cy="14.7"
      r="14.7"
      fill="fill-current text-blue-200 hover:text-blue-200"
    />
    <path
      fill="#f1f2f2"
      d="M23.6 15.9V22H20v-5.7c0-1.4-.5-2.4-1.8-2.4-1 0-1.6.7-1.8 1.3-.1.2-.1.5-.1.9V22h-3.5V11.4h3.5v1.5c.5-.7 1.3-1.8 3.2-1.8 2.3.1 4.1 1.6 4.1 4.8zM9.1 6.3c-1.2 0-2 .8-2 1.8S7.9 9.9 9 9.9c1.2 0 2-.8 2-1.8.1-1-.7-1.8-1.9-1.8zM7.3 22h3.5V11.4H7.3V22z"
    />
  </svg>
)

export default SVG
