import React from "react"

const SVG = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`${className || ""}`}
  >
    <path
      className="fill-current"
      d="M435.1 298.2l-2.372 1.28c-9.582-11.628-26.183-16.134-38.831-9.724-.045.022-.09.046-.134.069l-5.6 3.015a42.527 42.527 0 00-48.7-4.4l-30.155 16.4h-40.126a57.961 57.961 0 01-25.09-5.749l-5.52-2.67a94.693 94.693 0 00-98.552 9.625c-.131.1-.259.2-.381.313l-13.327 11.864a46.226 46.226 0 01-17.443 9.723L101.3 303.1a6 6 0 00-5.739-4.25H31.108a6 6 0 00-5.956 6.726l21.484 176.16a6 6 0 005.956 5.274h96.674a6 6 0 005.739-7.75l-12.7-41.674 39.554-25a23.8 23.8 0 0117.365-2.247c.124.031.249.059.375.081l80.459 14.816a104.117 104.117 0 0064.337-9.084 5.918 5.918 0 00.734-.43l139.164-95.552a6 6 0 001.744-8.041A38.2 38.2 0 00435.1 298.2zm-35.717 2.228c7.315-3.663 16.524-.66 22.406 4.953l-52.772 28.47-3.1 1.633a45.752 45.752 0 00-7.228-13.131zm-54.113-1.485c.047-.027.1-.053.142-.081a30.516 30.516 0 0131.037.292L349.618 313.6a45.592 45.592 0 00-17.138-7.713zM57.9 475.01L37.885 310.85h53.229l50.05 164.16zm280.784-69.431a92.038 92.038 0 01-56.479 7.848l-80.261-14.78a35.777 35.777 0 00-26.085 3.535 4.925 4.925 0 00-.277.165l-36.915 23.337-26.3-86.251a58.265 58.265 0 0021.917-12.257l13.139-11.7a82.736 82.736 0 0185.918-8.265l5.519 2.67a70.036 70.036 0 0030.315 6.946H322.7a33.851 33.851 0 0133.2 27.49l-38.795-1.755a232.313 232.313 0 00-42.22 1.951 6 6 0 001.646 11.886 219.979 219.979 0 0140.031-1.848l45.656 2.065c.091 0 .182.006.272.006a6 6 0 006-6c0-.971-.041-1.933-.1-2.89l6.261-3.3 66.149-35.676a26.214 26.214 0 0131.544 5.056z"
    />
    <path
      className="fill-current"
      d="M76.974 450.188a18.89 18.89 0 1018.89-18.89 18.911 18.911 0 00-18.89 18.89zm18.89-6.89a6.89 6.89 0 11-6.89 6.89 6.9 6.9 0 016.89-6.888zM301.622 90.305V70.533a45.544 45.544 0 00-91.087 0v19.772a45.544 45.544 0 0091.087 0zm-79.087 0V70.533a33.544 33.544 0 0167.087 0v19.772a33.544 33.544 0 01-67.087 0zM182.668 259.425H329.49a6 6 0 006-6v-13.772H436a6 6 0 006-6v-38.944a44.973 44.973 0 00-42.108-44.856c-3.2-.235-4.845 1.791-6.179 3.418a27.535 27.535 0 01-43.442-1.061 6 6 0 00-4.975-2.433 44.48 44.48 0 00-22.071 6.439 50.437 50.437 0 00-35.2-17.459c-3.1-.126-4.668 1.756-6.187 3.6a32.308 32.308 0 01-50.977-1.245 5.982 5.982 0 00-4.975-2.432 50.215 50.215 0 00-34.845 15.2 50.536 50.536 0 00-2.178 2.393 44.612 44.612 0 00-20.457-6.422c-3.189-.235-4.846 1.791-6.179 3.418a27.534 27.534 0 01-43.441-1.061 6.015 6.015 0 00-4.975-2.433A44.955 44.955 0 0070 194.709v38.944a6 6 0 006 6h100.668v13.772a6 6 0 006 6zm160.066-97.458a39.541 39.541 0 0059.2.155A32.987 32.987 0 01430 194.709v32.944h-94.51v-38.491a50.2 50.2 0 00-5.545-22.974 32.433 32.433 0 0112.789-4.221zM223.3 150.862a44.315 44.315 0 0066.934.159 38.54 38.54 0 0133.26 38.141v58.263H188.668v-58.263a38.763 38.763 0 0134.632-38.3zM82 227.653v-32.944a33.179 33.179 0 0129.254-32.738 39.538 39.538 0 0059.191.151 32.735 32.735 0 0111.71 4.132 50.271 50.271 0 00-5.487 22.908v38.491z"
    />
    <path
      className="fill-current"
      d="M412.354 108.179V90.872a40.612 40.612 0 10-81.224 0v17.307a40.612 40.612 0 1081.224 0zm-69.224 0V90.872a28.612 28.612 0 1157.224 0v17.307a28.612 28.612 0 11-57.224 0zM180.87 108.179V90.872a40.612 40.612 0 10-81.224 0v17.307a40.612 40.612 0 1081.224 0zm-69.224 0V90.872a28.612 28.612 0 1157.224 0v17.307a28.612 28.612 0 11-57.224 0z"
    />
  </svg>
)

export default SVG
