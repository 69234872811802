import React from "react"

const SVG = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 112.2 112.2"
    className="w-12"
  >
    <circle cx="56.1" cy="56.1" r="56.1" fill="#3b4144" />
    <path
      d="M70.2 58.3h-10V95H45V58.3h-7.2V45.4H45v-8.3c0-6 2.8-15.3 15.3-15.3h11.2v12.5h-8.2c-1.3 0-3.2.7-3.2 3.5v7.6h11.3l-1.2 12.9z"
      fill="#fff"
    />
  </svg>
)

export default SVG
