import PropTypes from "prop-types"
import React from "react"

import Header from "./header"
import Footer from "./footer"
import Seo from "./seo"

function Layout({ children }) {
  return (
    <>
      <Seo />
      <Header />
      <div className="md:mt-56 md:mb-122 bg-white">{children}</div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
