import React from "react"

const SVG = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29.5 29.5"
    className="w-12"
  >
    <circle cx="14.7" cy="14.7" r="14.7" fill="#3b4144" />
    <path
      fill="#f1f2f2"
      d="M23.8 10.6c-.6.3-1.3.5-2 .6.7-.4 1.3-1.1 1.5-1.9-.7.4-1.4.7-2.2.9-.6-.7-1.6-1.1-2.6-1.1-1.9 0-3.5 1.6-3.5 3.5 0 .3 0 .5.1.8-2.9-.1-5.5-1.5-7.3-3.7-.3.5-.5 1.1-.5 1.8 0 1.2.6 2.3 1.6 2.9-.6 0-1.1-.2-1.6-.4 0 1.7 1.2 3.1 2.8 3.5-.3.1-.6.1-.9.1-.2 0-.4 0-.7-.1.4 1.4 1.7 2.4 3.3 2.4-1.2.9-2.7 1.5-4.4 1.5h-.8C8.2 22.4 10 23 12 23c6.5 0 10-5.4 10-10v-.5c.7-.6 1.3-1.2 1.8-1.9z"
    />
  </svg>
)

export default SVG
