import React from "react"

import Twitter from "./Twitter"
import Facebook from "./Facebook"
import Instagram from "./Instagram"
import Linkedin from "./Linkedin"

import Award from "./Award"
import Building from "./Building"
import Document from "./Document"
import Services from "./Services"

const Icon = props => {
  switch (props.name) {
    case "facebook":
      return <Facebook {...props} />
    case "twitter":
      return <Twitter {...props} />
    case "instagram":
      return <Instagram {...props} />
    case "linkedin":
      return <Linkedin {...props} />
    case "award":
      return <Award {...props} />
    case "building":
      return <Building {...props} />
    case "document":
      return <Document {...props} />
    case "services":
      return <Services {...props} />
    default:
      return
  }
}

export default Icon
