import React from "react"
import { Title, Meta } from "react-head"

const Seo = () => (
  <>
    <Title>Hawthorne Consulting Chartered Surveyors</Title>
    <Meta
      name="description"
      content="Hawthorne Consulting Quantity Surveyors"
    />
    <Meta
      name="keywords"
      content="quantity surveyors, belfast, northern ireland, expert witness,"
    />
  </>
)

export default Seo
