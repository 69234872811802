import React from "react"

const SVG = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89.5 89.5"
    className="w-12"
  >
    <circle cx="44.7" cy="44.7" r="44.7" fill="#3b4144" />
    <path
      d="M58 72H31.5c-7.7 0-14-6.3-14-14V31.5c0-7.7 6.3-14 14-14H58c7.7 0 14 6.3 14 14V58c0 7.7-6.3 14-14 14zM31.5 23.5c-4.4 0-8 3.6-8 8V58c0 4.4 3.6 8 8 8H58c4.4 0 8-3.6 8-8V31.5c0-4.4-3.6-8-8-8H31.5z"
      fill="#fff"
    />
    <path
      d="M44.8 58.2c-7.7 0-13.9-6.2-13.9-13.9s6.2-13.9 13.9-13.9 13.9 6.2 13.9 13.9-6.2 13.9-13.9 13.9zm0-21.8c-4.4 0-7.9 3.5-7.9 7.9 0 4.3 3.5 7.9 7.9 7.9s7.9-3.5 7.9-7.9c0-4.3-3.5-7.9-7.9-7.9z"
      fill="#fff"
    />
  </svg>
)

export default SVG
