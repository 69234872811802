import React, { Component } from "react"
import { Link } from "gatsby"

import Logo from "./logo"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navOpen: false,
    }
  }

  handleClick = () => {
    this.setState(prevState => ({
      navOpen: !prevState.navOpen,
    }))
  }

  render() {
    return (
      <header className="md:fixed top-0 left-0 right-0 bg-white z-50 py-10 mb-6 shadow-md">
        <div className="container flex flex-wrap items-center">
          <Link to="/" className="flex-shrink-0 w-64 z-50">
            <Logo />
          </Link>
          <div className="flex-grow text-right hamburger z-50 md:hidden">
            <button onClick={this.handleClick}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="17"
                viewBox="0 0 50 34"
                className="text-black"
              >
                <path
                  className="fill-current"
                  d="M0 4h50V0H0M0 19h50v-4H0M0 34h50v-4H0"
                />
              </svg>
            </button>
          </div>

          {/* <nav className="nav flex flex-col md:flex-row flex-grow justify-center md:justify-end md:text-right invisible md:visible fixed md:relative inset-0 bg-gray-200 md:bg-white z-40 pt-64 md:pt-0 min-h-screen md:min-h-0 md:h-auto overflow-y-auto overflow-x-hidden"> */}
          <nav
            className={`nav flex flex-col md:flex-row flex-grow justify-center md:justify-end md:text-right fixed md:relative inset-0 bg-gray-200 md:bg-white z-40 pt-64 md:pt-0 min-h-screen md:min-h-0 md:h-auto overflow-y-auto overflow-x-hidden md:visible
           ${this.state.navOpen ? "visible" : "invisible"}`}
          >
            <Link
              to="/"
              activeClassName="active-link"
              className="flex self-center text-white md:text-black text-3xl md:text-base md:mr-5 px-4 py-2 hover:border-blue-100 md:border-white md:border-b-2"
            >
              Home
            </Link>
            <Link
              to="/about"
              activeClassName="active-link"
              className="flex self-center text-white md:text-black text-3xl md:text-base md:mr-5 px-4 py-2 hover:border-blue-100 md:border-white md:border-b-2"
            >
              About Us
            </Link>
            <Link
              to="/services"
              activeClassName="active-link"
              className="flex self-center text-white md:text-black text-3xl md:text-base md:mr-5 px-4 py-2 hover:border-blue-100 md:border-white md:border-b-2"
            >
              Services
            </Link>
            <Link
              to="/projects"
              activeClassName="active-link"
              className="flex self-center text-white md:text-black text-3xl md:text-base md:mr-5 px-4 py-2 hover:border-blue-100 md:border-white md:border-b-2"
            >
              Projects
            </Link>
            <Link
              to="#clients"
              activeClassName="active-link"
              className="flex self-center text-white md:text-black text-3xl md:text-base md:mr-5 px-4 py-2 hover:border-blue-100 md:border-white md:border-b-2"
            >
              Clients
            </Link>
            <Link
              to="/contact"
              activeClassName="active-link"
              className="flex self-center text-white md:text-black text-3xl md:text-base px-4 py-2 hover:border-blue-100 md:border-white md:border-b-2"
            >
              Contact
            </Link>
          </nav>
        </div>
      </header>
    )
  }
}

export default Header
